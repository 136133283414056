import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { useAppSelector } from '../../redux/hooks';
import { selectGlobal } from '../../redux/state/globalSlice';

const Label = styled.label`
  font-weight: bold;
  color: #333;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 300px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 2rem;
  font-weight: 400;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
`;

interface ImageUploadControlProps {
  data: string;
  handleChange: (path: string, value: string) => void;
  path: string;
  id: any;
  label: any;
}

const CustomDetailsLargeReadOnlyTokenInt: React.FC<ImageUploadControlProps> = ({
  data, // Provide a default value of an empty string if data is undefined
  handleChange,
  path,
  id,
  label,
}) => {
  const global = useAppSelector(selectGlobal);
  const currentAssistant = global?.currentAssistant;
  const currentForm = global?.currentFormState;
  const currenFormOnUpdate = global?.currentFormStateOnUpdate;

  const classesHandler = (classesArray: any) => {
    let classNames = classesArray?.map((c: any) => c.name)?.join(', ');
    let guidanceTexts = classesArray
      ?.map((c: any) => `${c.name}: "${c.guidance} of ${c.name}"`)
      ?.join('\n');

    return {
      classes: classNames,
      guidance: guidanceTexts,
    };
  };

  function replacePlaceholders(template: any, data: any) {
    const executeClasses = classesHandler(
      currentForm?.classes || currenFormOnUpdate?.classes
    );

    console.log(executeClasses);

    return template
      ?.replace('{objective}', currentAssistant?.assistant_type)
      ?.replace(
        '{guidance}',
        currentForm?.guidance || currenFormOnUpdate?.guidance
      )
      ?.replace('{classes_guidance}', executeClasses.guidance)
      ?.replace('{classes}', executeClasses.classes)
      ?.replace(
        '{returned_object_instructions}',
        currentForm?.tokens?.returned_object_instructions ||
          currenFormOnUpdate?.tokens?.returned_object_instructions
      )
      ?.replace(
        '{returned_object}',
        currentForm.tokens?.returned_object ||
          currenFormOnUpdate.tokens?.returned_object
      )
      ?.replace(
        '{class}',
        currentForm.tokens?.class_name || currenFormOnUpdate.tokens?.class_name
      );
  }

  const handleTextareaChange = () => {
    handleChange(
      path,
      replacePlaceholders(
        currentAssistant?.instructions ||
          currentAssistant?.assistantTypeObject?.instructions,
        currentForm
      )
    );
  };

  useEffect(() => {
    handleTextareaChange();
  }, [currentForm?.tokens]);

  return (
    <div>
      {/* <Label htmlFor={id}>{'Customer Story'}</Label> */}
      <Textarea
        placeholder={`${label}...`}
        id={id}
        readOnly={true}
        value={
          data ||
          replacePlaceholders(
            currentAssistant?.instructions ||
              currentAssistant?.assistantTypeObject?.instructions,
            currentForm
          )
        }
        onChange={handleTextareaChange}
      />
    </div>
  );
};

export default withJsonFormsControlProps(CustomDetailsLargeReadOnlyTokenInt);
