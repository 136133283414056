/* eslint-disable styled-components-a11y/no-noninteractive-element-interactions */
/* eslint-disable styled-components-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Button from './Button';
import axios from 'axios';
import Input from './Select/Input';
import { Autocomplete, TextField, TextareaAutosize } from '@mui/material';
import {
  setCurrentAssistantState,
  setCurrentFormStateOnUpdate,
  setCurrentHTML,
  setCurrentPopupData,
  setFormErrorsRedux,
  setRowObject,
  setShowAssistantEditForm,
  setShowMenuToolForm,
  setShowSideBarPopup,
  setViewAccount,
  updateTempStatePopup,
} from '../redux/state/globalSlice';
import { useDynamicQueryMutation } from '../redux/api/authSlice';
import { uploadImageWithFile } from '../utils/uploadImage';
import { config } from '../config/config';
import { FadeInDiv } from '../style/Animations';
import { JSONForm } from './JSONForm';
import { useOutsideAlerter } from '../utils/detectOutsideClick';
import { useParams } from 'react-router';

const Wrapper = styled.div`
  display: grid;
  justify-content: center;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  min-height: 800px;
  /* background: #64646449; */
  position: absolute;
  border-radius: 10px;
  /* padding-top: 100px; */
  z-index: 99;
`;

const Container = styled.div`
  display: grid;
  justify-self: center;
  align-self: center;
  width: 982px;
  min-height: 300px;
  background: rgba(255, 255, 255, 1);
  box-shadow: -4px 0px 15px 3px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 4rem;
`;

const Main = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 2rem 2rem;
`;

const Cross = styled.img`
  max-width: 15px;
  cursor: pointer;
  justify-self: flex-end;
`;

const Title = styled.p`
  color: #293748;
  font-size: 2.4rem;
  font-weight: 600;
  margin-bottom: 2rem;
`;

const Footer = styled.div`
  display: flex;
  gap: 1rem;
  align-self: flex-end;
  justify-self: flex-end;
`;

const ErrorMsg = styled.p`
  color: red;
`;

interface IProps {
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  dispatch: any;
  popupData: any;
  getDefaultValue: boolean;
  pickDefaultValueFrom: any;
  globalState: any;
  setFechtedData?: any;
  setFormData: any;
  setShowButtonPopup: any;
  bodyProperty: string;
  pickDefaultValueColumn: any;
  pushToArray: boolean;
  liveUpdateObject?: boolean;
  fetchedData: any;
  redirectAfterSave?: string;
}

const Popup: React.FC<IProps> = ({
  setShowPopup,
  dispatch,
  popupData,
  pickDefaultValueFrom,
  globalState,
  setFechtedData,
  setFormData,
  bodyProperty,
  setShowButtonPopup,
  pushToArray,
  pickDefaultValueColumn,
  liveUpdateObject,
  fetchedData,
  redirectAfterSave,
}) => {
  const [data, setData] = React.useState<any>({});
  const [image, setImage] = React.useState('');
  const [editStarted, setEditStarted] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState<any>('');
  const [imageUploading, setImageUploading] = React.useState(false);
  const [dynamicQuery, result] = useDynamicQueryMutation();
  const [formErrors, setFormErrors] = React.useState({});
  const { settings } = globalState;
  const { tempStatePopup } = settings;

  const popup = React.useRef<any>();
  const params = useParams<any>();

  const updateData = ({
    property,
    value,
  }: {
    property: string;
    value: any;
  }) => {
    setData((prev: any) => ({ ...prev, [property]: value }));
  };

  const onOutsideClick = () => {
    dispatch(setViewAccount(false));
    setShowPopup(false);
    setShowButtonPopup(false);
    dispatch(setShowSideBarPopup(false));
    dispatch(setCurrentPopupData({}));
    dispatch(setRowObject({}));
    dispatch(setFormErrorsRedux(''));
    dispatch(setCurrentHTML(''));
    dispatch(setShowMenuToolForm(false));
    dispatch(setShowAssistantEditForm(false));
    dispatch(setCurrentAssistantState({}));
    dispatch(setCurrentFormStateOnUpdate({}));
    // Clear side
    setFormData([]);
  };

  // useOutsideAlerter(popup, onOutsideClick);

  const handleFileSelect = async (event: any) => {
    setImageUploading(true);
    updateData({
      value: '',
      property: 'logo',
    });
    try {
      const formData = new FormData();
      formData.append('file', event.target.files[0]);
      const img = await uploadImageWithFile(formData);
      updateData({
        value: img,
        property: 'logo',
      });
    } catch (error) {
    } finally {
      setImageUploading(false);
    }
  };

  const handleSave = () => {
    let endpoint =
      popupData?.endpoint?.replace(
        Object?.keys(params)?.[0],
        params?.[Object?.keys(params)?.[0]]
      ) || '/';
    dispatch(setFormErrorsRedux(''));
    dispatch(setViewAccount(false));
    dynamicQuery({
      endpoint: endpoint,
      method: 'POST',
      body: {
        [bodyProperty]: data,
        sourceId: pickDefaultValueColumn?.sourceId,
        objectId: pickDefaultValueColumn?._id,
      },
    });

    if (pickDefaultValueFrom) {
      dispatch(updateTempStatePopup(data));
    }
    // Finally
    dispatch(setCurrentHTML(''));
  };

  const handleDeleteItem = async (
    name: string,
    removeEndpoint: string,
    objectId: string
  ) => {
    const typeNeeded = name || 'delete';
    const promptResult = prompt(`Type ${typeNeeded} to drop the item`);
    if (promptResult === typeNeeded) {
      // delete item...
      try {
        await dynamicQuery({
          endpoint: removeEndpoint,
          method: 'POST',
          body: {
            objectId,
          },
        });

        setFechtedData(fetchedData?.filter((fd: any) => fd?._id !== objectId));
        setShowPopup(false);
        setShowButtonPopup(false);
        dispatch(setShowButtonPopup(false));
      } catch (error) {
        console.log(error);
      }
    }
  };

  React.useEffect(() => {
    if (result.status === 'fulfilled') {
      if (pushToArray) {
        setFechtedData((prev: any) => [
          ...prev,
          { ...result?.data?.data, id: result?.data?.data?._id },
        ]);
      }
      // When update a item on form, live update the related object on UI.
      if (liveUpdateObject) {
        let filtered = fetchedData?.map((fd: any) => {
          if (fd?._id === pickDefaultValueColumn?._id) {
            return {
              ...data,
            };
          } else {
            return fd;
          }
        });

        setFechtedData(filtered);

        // Live update v2
        // setFechtedData((prev: any) => [...filtered, data]);
        // live update
        // const dataKey = Object.keys(data);
        // const dataValues = Object.values(data);
        // console.log(dataKey, dataValues);

        // dataKey.forEach((dt, index) => {
        //   console.log(findOne[dt]);
        //   // findOne[dt] = dataValues[index];
        // });
      }
      setShowPopup(false);
      setShowButtonPopup(false);
      dispatch(setShowMenuToolForm(false));
      dispatch(updateTempStatePopup([]));
      dispatch(setShowSideBarPopup(false));
      dispatch(setCurrentAssistantState({}));
      dispatch(setShowAssistantEditForm(false));
      dispatch(setCurrentFormStateOnUpdate({}));

      // Incase of side form
      if (redirectAfterSave) {
        window.location.replace(redirectAfterSave);
      }
      if (popupData?.refreshPage) {
        window.location.reload();
      }
    }
    if (result.status === 'rejected') {
      if ('data' in result?.error) {
        setErrorMsg(result?.error?.data || '');
      }
    }
  }, [result]);

  // Update form state on update
  useEffect(() => {
    dispatch(setCurrentFormStateOnUpdate(data));
  }, [data]);

  return (
    <Wrapper>
      <FadeInDiv>
        <Container ref={popup}>
          <Cross alt="" src="/close.png" onClick={onOutsideClick} />
          <Title>{popupData?.title}</Title>
          <Main>
            <JSONForm
              popupData={popupData}
              setData={setData}
              data={
                pickDefaultValueColumn?.length === 0
                  ? Object.keys(data)?.length
                    ? data
                    : popupData.data
                  : pickDefaultValueColumn
              }
              formErrors={formErrors}
              setFormErrors={setFormErrors}
            />
          </Main>
          <Footer>
            <Button text="Cancel" theme="pageCancel" onClick={onOutsideClick} />
            <Button
              onClick={handleSave}
              text={popupData?.customButtonText || 'Save'}
              theme={Object.keys(formErrors).length > 0 ? 'loading' : 'page'}
            />
            {popupData?.list?.find((pd: any) => pd?.button) && (
              <Button
                onClick={() =>
                  handleDeleteItem(
                    'delete',
                    popupData?.list?.find((pd: any) => pd?.button)?.endpoint,
                    pickDefaultValueColumn?._id
                  )
                }
                text="Delete"
                theme={'danger'}
              />
            )}
          </Footer>
          <ErrorMsg>{errorMsg?.msg}</ErrorMsg>
        </Container>
      </FadeInDiv>
    </Wrapper>
  );
};

export default Popup;
