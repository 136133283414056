/* eslint-disable no-lone-blocks */
import * as React from 'react';
import Box from '@mui/material/Box';
import { FadeInDiv } from '../../style/Animations';
import styled from 'styled-components';

import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridValueGetterParams,
  GridToolbar,
  useGridApiRef,
  GridRenderCellParams,
} from '@mui/x-data-grid';

import { useDynamicQueryMutation } from '../../redux/api/authSlice';
import moment from 'moment';
import { Button } from '@mui/material';
import NestedDropdown from '../NestedDropdown';
import { useAppSelector } from '../../redux/hooks';
import { selectGlobal } from '../../redux/state/globalSlice';
import { log } from 'util';

const Dots = styled.div`
  cursor: pointer;
`;

export default function Table({
  rows,
  columns,
  updateDataEndpoint,
  funnel,
  hideInitial,
  setShowPopup,
  setShowButtonPopup,
  setFormData,
  setPickDefaultValueColumn,
  dataTable,
}: any) {
  const [updatedRow, setUpdatedRow] = React.useState<any>([]);
  const [updatedField, setUpdatedField] = React.useState<any>(null);
  const [rowUpdated, setRowUpdated] = React.useState(false);
  const [fieldUpdated, setFieldUpdated] = React.useState(false);
  const [rowState, setRowState] = React.useState<any>([]);
  const [deletedItems, setDeletedItems] = React.useState<string[]>([]);
  const [dynamicQuery, result] = useDynamicQueryMutation();
  const { authenticated } = useAppSelector(selectGlobal);

  const isOwner: any = authenticated?.user?.userRole?.toLowerCase() === 'owner';

  const deleteOne = async (endpoint: string, row: any) => {
    await dynamicQuery({
      endpoint,
      method: 'POST',
      body: {
        sourceId: row?.sourceId,
        objectId: row?._id,
      },
    });
    const removed = rowState?.filter((r: any) => r?._id !== row?._id);
    setRowState(removed);
  };

  const handleExtraComponents = () => {
    return columns
      ?.filter((ctx: any) => ctx?.dots)
      ?.map((ctx: any) => {
        return {
          ...ctx,
          field: ctx?.dots ? 'Actions Menu' : ctx.field,
          renderCell: ctx?.dots
            ? (params: GridRenderCellParams<any>) => (
                <Dots>
                  <NestedDropdown
                    setFormData={setFormData}
                    setPickDefaultValueColumn={setPickDefaultValueColumn}
                    setRowState={setRowState}
                    rowState={rowState}
                    LI={ctx?.LI}
                    optionList={ctx?.optionList}
                    sourceId={params?.row?.sourceId}
                    id={params?.row?._id}
                    updateTableEndpoint={updateDataEndpoint}
                    updatedProperty={ctx?.property}
                    setShowButtonPopup={setShowButtonPopup}
                    params={params}
                    dataTable={dataTable}
                    deleteOne={deleteOne}
                    setShowPopup={setShowPopup}
                  />
                </Dots>
              )
            : undefined,
        };
      });
  };

  const handleNestedFromServer = () => {
    return (
      columns
        // ?.filter((ctx: any) => (isOwner ? ctx : ctx?.field !== 'users'))
        ?.filter((ctx: any) => ctx?.field !== 'users')
        ?.filter((ctx: any) => !ctx?.dots)
        ?.map((ctx: any) => {
          console.log(ctx);
          if (ctx?.field === 'dealSizeManupulated') {
            const fileredDealSize = rows?.map((r: any) => ({
              ...r,
              dealSizeManupulated:
                r?.dealSize || r?.dealSize == 0
                  ? String(`$${r?.dealSize}`)
                  : '',
            }));

            rows = fileredDealSize;
            return ctx;
          }
          return ctx;
        })
        ?.map((ctx: any) =>
          ctx?.getNested
            ? {
                ...ctx,
                valueGetter: (params: GridValueGetterParams) =>
                  params.row?.data[ctx?.getNested],
              }
            : ctx?.dateString
            ? {
                ...ctx,
                valueGetter: (params: GridValueGetterParams) => {
                  return params.row?.[ctx?.field]
                    ? new Date(params.row[ctx.field])
                        .toISOString()
                        .split('T')[0] +
                        ' ' +
                        new Date(params.row[ctx.field])
                          .toISOString()
                          .split('T')[1]
                          .substr(0, 5)
                    : 'N/A';
                },
              }
            : {
                ...ctx,
                renderCell: ctx?.button
                  ? ctx?.buttonType === 'delete'
                    ? (params: GridRenderCellParams<Date>) => (
                        <strong>
                          <Button
                            onClick={() =>
                              deleteOne(ctx?.endpoint, params?.row)
                            }
                            variant="contained"
                            color="error"
                            size="small"
                            style={{ marginLeft: 16 }}
                            tabIndex={params.hasFocus ? 0 : -1}
                          >
                            {ctx?.buttonName}
                          </Button>
                        </strong>
                      )
                    : (params: GridRenderCellParams<Date>) => (
                        <strong>
                          <Button
                            onClick={() => {
                              // set popupdata custom for spesific button from data table config
                              setFormData(dataTable?.popupData || []);
                              setPickDefaultValueColumn(updatedRow || []);
                              setShowButtonPopup(true);
                            }}
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginLeft: 16 }}
                            tabIndex={params.hasFocus ? 0 : -1}
                          >
                            {ctx?.buttonName}
                          </Button>
                        </strong>
                      )
                  : undefined,
              }
        )
    );
  };

  const handleStageRules = (row: any, rules: any) => {
    // make it support array rules, only support object rules for now.

    if (rules === undefined) {
      return { allowEdit: true };
    }

    const allFieldsExists = rules?.mandatory_fields?.every(
      (mf: any) => row?.data?.[mf]
    );
    if (allFieldsExists) {
      return {
        filteredStages: columns?.find(
          (col: any) => col?.field === funnel?.funnelField
        )?.valueOptions,
        allowEdit: true,
      };
    }
    const filteredStages = columns
      ?.find((col: any) => col?.field === funnel?.funnelField)
      ?.valueOptions?.filter(
        (stage: any) => !rules?.stages?.includes(stage?.toLowerCase())
      );

    return { filteredStages, allowEdit: false };
  };

  const handleRules = () => {
    return [...handleExtraComponents(), ...handleNestedFromServer()];
  };

  const processRowUpdate = (newRow: any) => {
    const updatedRow = { ...newRow, isNew: false };
    setUpdatedRow(updatedRow);
    //handle send data to api
    return updatedRow;
  };

  React.useEffect(() => {
    setRowState(rows);
  }, [rows]);
  React.useEffect(() => {
    setRowUpdated(true);
  }, [updatedRow]);
  React.useEffect(() => {
    setFieldUpdated(true);
  }, [updatedField]);

  React.useEffect(() => {
    if (rowUpdated && fieldUpdated) {
      setUpdatedField(null);
      setUpdatedRow([]);
      setFieldUpdated(false);
      setRowUpdated(false);

      if (updatedField) {
        dynamicQuery({
          endpoint: updateDataEndpoint,
          method: 'POST',
          body: {
            sourceId: updatedField?.row?.sourceId,
            objectId: updatedField?.row?._id,
            updated: {
              [updatedField?.field]: updatedRow[updatedField?.field],
            },
            funnel: funnel ? funnel : [],
          },
        });
      }
    }
  }, [rowUpdated, fieldUpdated]);

  const memoryTool = React.useCallback(() => {
    const showExport = authenticated?.user?.userRole?.toLowerCase() === 'owner'; // Your condition here
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarQuickFilter />
        {/* {showExport && <GridToolbarExport />} */}
        {/* Other toolbar items */}
      </GridToolbarContainer>
    );
  }, []);

  return (
    <FadeInDiv>
      <Box sx={{ height: 780, width: '1100px' }}>
        <DataGrid
          sx={{ fontSize: '15.2px', border: 0 }}
          rows={rowState}
          slots={{ toolbar: memoryTool }}
          localeText={{
            toolbarDensity: 'Spacing',
          }}
          onCellEditStart={(e: any) => {
            {
              const runStageRule = (e.colDef.editable = handleStageRules(
                e?.row,
                columns?.find((col: any) => col?.field === e.colDef?.field)
                  ?.stages_rules?.[0]
              ));

              // readjust single select options
              e.colDef.valueOptions =
                runStageRule?.filteredStages || e.colDef.valueOptions;
              // block the editable status due to stages rules
              e.colDef.editable = runStageRule?.allowEdit;
            }
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
              printOptions: { disableToolbarButton: true },
            },
            columnsPanel: {},
          }}
          columns={handleRules()}
          processRowUpdate={processRowUpdate}
          density="compact"
          onCellEditStop={(e: any) => {
            setUpdatedField(e);
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 13,
              },
            },
          }}
          pageSizeOptions={[50]}
          disableRowSelectionOnClick
        />
      </Box>
    </FadeInDiv>
  );
}
