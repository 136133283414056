/* eslint-disable styled-components-a11y/no-static-element-interactions */
/* eslint-disable styled-components-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState, useRef } from 'react';
import ElementPopper from 'react-element-popper';
import styled, { keyframes } from 'styled-components';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useDynamicQueryMutation } from '../redux/api/authSlice';
import { fadeIn } from 'react-animations';
import { FadeInDiv } from '../style/Animations';
import { useOutsideAlerter } from '../utils/detectOutsideClick';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import {
  selectGlobal,
  setAllowDeleteAction,
  setCurrentAssistantState,
  setJsonViewerData,
  setRowObject,
  setShowAssistantEditForm,
  setShowDeleteAlert,
  setShowJsonViewerPopup,
} from '../redux/state/globalSlice';
import useConditionalAssistantForm from '../hooks/useConditionalAssistantForm';

const Wrapper = styled.div`
  button {
    background: none;
    cursor: pointer;
  }
  .dropdown {
    position: absolute;
    z-index: 999;
    top: 0;
    margin: 0;
    padding: 1rem 0;
    list-style: none;
    background-color: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .dropdown ul {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-top: -27px;
  }
  .dropdown li {
    min-width: 170px;
    cursor: pointer;
    padding: 0.7rem 1.6rem;
    color: rgba(41, 55, 72, 1);
    font-size: 1.5rem;
    background-color: #f1f1f1;
  }

  .dropdown li:hover {
    background: #fff;
  }

  ul {
    width: 100%;
  }
`;

const List = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  align-content: center;
  gap: 1.5rem;
  justify-content: flex-start;
  padding: 0.7rem 1.6rem;

  border-radius: 5px;
  color: rgba(41, 55, 72, 1);
  font-size: 1.5rem;
  &:hover {
    background: rgba(245, 247, 251, 1);
  }
`;
const Icon = styled.img`
  width: 100%;
  max-width: 20px;
  width: 20px;
`;

export default function NestedDropdown({
  sourceId,
  deleteOne,
  id,
  updateTableEndpoint,
  updatedProperty,
  LI,
  setRowState,
  rowState,
  optionList,
  setShowButtonPopup,
  setFormData,
  setPickDefaultValueColumn,
  dataTable,
  setShowPopup,
  params,
}: {
  sourceId: string;
  id: string;
  updateTableEndpoint: string;
  updatedProperty: string;
  LI: any[];
  setRowState: any;
  rowState: any;
  optionList: any;
  setShowButtonPopup: any;
  setFormData: any;
  setPickDefaultValueColumn: any;
  dataTable: any;
  params: any;
  deleteOne: any;
  setShowPopup: any;
}) {
  const [state, setState] = useState<any>({});
  const [dynamicQuery, result] = useDynamicQueryMutation();
  const dropdown = useRef(null);
  const [deleteItem, setDeleteItem] = useState<any>({});
  const [foundObjectTemp, setFoundObjectTemp] = useState<any>({});
  const FoundObject = LI?.find((ctx) => ctx?.name === state?.property);
  const currentRow = rowState?.find((row: any) => row?._id === id);
  const dispatch = useAppDispatch();
  const GlobalState = useAppSelector(selectGlobal);
  const { handleForm } = useConditionalAssistantForm();

  React.useEffect(() => {
    if (GlobalState?.allowDeleteAction && foundObjectTemp?.endpoint) {
      // do smth
      deleteOne(foundObjectTemp?.endpoint, deleteItem);
      dispatch(setAllowDeleteAction(false));
    }
  }, [GlobalState?.allowDeleteAction]);

  const list = (
    <ul onClick={(e: any) => console.log(e)} className="dropdown">
      {FoundObject?.list
        // filter for boolean type
        ?.filter((ctx: any, index: number) => {
          if (FoundObject?.type === 'boolean') {
            if (currentRow?.[FoundObject?.property] && index === 1) {
              return ctx;
            }
            if (!currentRow?.[FoundObject?.property] && index === 0) {
              return ctx;
            }
          }
          if (FoundObject?.type !== 'boolean') {
            return ctx;
          }
        })
        ?.map((ctx: any) => (
          <li
            onClick={async (e) => {
              try {
                await dynamicQuery({
                  endpoint: updateTableEndpoint,
                  method: 'POST',
                  body: {
                    sourceId: sourceId,
                    objectId: id,
                    updated: {
                      [FoundObject?.property]: ctx?.value,
                    },
                  },
                });

                const findUpdated = rowState?.find(
                  (row: any) => row?._id === id
                );
                findUpdated[FoundObject?.property] = ctx?.value;
                setRowState((prev: any) => [...rowState]);
              } catch (error) {
                console.log(error);
              } finally {
                setState({});
              }
            }}
          >
            {ctx?.title}
          </li>
        ))}
    </ul>
  );
  const onOutsideClick = () => {
    setState({});
  };
  useOutsideAlerter(dropdown, onOutsideClick);
  if (!optionList?.length) {
    return <div></div>;
  }

  const mainList = (
    <FadeInDiv>
      <div ref={dropdown} className="dropdown">
        {optionList
          .filter((oList: any) => {
            const rowData = params?.row?.data;

            if (oList?.has) {
              return rowData?.[oList?.has];
            }
            if (!rowData?.[oList?.hasNot] && !rowData?.[oList?.has]) {
              return true;
            }
            return oList?.hasNot && !rowData?.[oList?.hasNot];
          })
          ?.map((ctx: any, index: number) => (
            <div
              key={index}
              className="item"
              onMouseOver={() =>
                setState({
                  main: true,
                  property: ctx?.value,
                })
              }
            >
              <ElementPopper
                containerStyle={{ width: '170px' }}
                element={
                  <List
                    onClick={() => {
                      if (FoundObject?.type === 'openAssistant') {
                        setPickDefaultValueColumn(params?.row || []);
                        dispatch(setCurrentAssistantState(params?.row));
                        dispatch(setRowObject(params?.row));

                        handleForm(
                          params?.row?.assistantTypeObject?.function_type,
                          '/ai/updateAssistant'
                        );
                        dispatch(setShowAssistantEditForm(true));
                      }
                      if (FoundObject?.type === 'open') {
                        setPickDefaultValueColumn(params?.row || []);
                        dispatch(setRowObject(params?.row));
                      }

                      if (FoundObject?.type === 'show') {
                        dispatch(
                          setJsonViewerData(params?.row?.[ctx?.showProperty])
                        );
                        dispatch(setShowJsonViewerPopup(true));
                      }

                      if (FoundObject?.type === 'delete') {
                        dispatch(setShowDeleteAlert(true));
                        setDeleteItem(params?.row);
                        setFoundObjectTemp(FoundObject);
                      }

                      if (FoundObject?.type !== 'open') return;
                      setFormData(dataTable?.popupData || []);
                      setShowButtonPopup(true);
                    }}
                  >
                    <Icon src={ctx?.icon} alt="" />
                    {ctx?.name}
                  </List>
                }
                popper={state?.property === ctx?.value ? list : undefined}
                position="right"
              />
            </div>
          ))}
      </div>
    </FadeInDiv>
  );

  return (
    <Wrapper>
      <ElementPopper
        element={
          <button onClick={() => setState({ main: !state.main })}>
            <MoreVertIcon />
          </button>
        }
        popper={state.main && mainList}
        position="top"
      />
    </Wrapper>
  );
}
function setShowButtonPopup(arg0: boolean): void {
  throw new Error('Function not implemented.');
}
