import React from 'react';
import styled from 'styled-components';
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';

const Label = styled.label`
  font-weight: bold;
  color: #333;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

interface ImageUploadControlProps {
  data: string;
  handleChange: (path: string, value: string) => void;
  path: string;
  id: any;
}

const CustomTextareaControl: React.FC<ImageUploadControlProps> = ({
  data, // Provide a default value of an empty string if data is undefined
  handleChange,
  path,
  id,
}) => {
  const handleTextareaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    handleChange(path, event.target.value);
  };

  return (
    <div>
      {/* <Label htmlFor={id}>{'Customer Story'}</Label> */}
      <Textarea id={id} value={data} onChange={handleTextareaChange} />
    </div>
  );
};

export default withJsonFormsControlProps(CustomTextareaControl);
