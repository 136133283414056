/* eslint-disable styled-components-a11y/no-noninteractive-element-interactions */
/* eslint-disable styled-components-a11y/no-static-element-interactions */
/* eslint-disable styled-components-a11y/click-events-have-key-events */
import React from 'react';
import styled from 'styled-components';
import { setJsonViewerData } from '../redux/state/globalSlice';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const Cross = styled.img`
  max-width: 15px;
  cursor: pointer;
  justify-self: flex-end;
  margin-bottom: 10px;
`;

const Dialog = styled.div`
  display: grid;
  width: 600px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
`;

interface PopupProps {
  isOpen: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  children?: any;
}

const DynamicPopupDialog: React.FC<PopupProps> = ({
  isOpen,
  onClose,
  children,
}) => {
  if (!isOpen) return null;

  const handleClose = () => {
    onClose(false);
  };

  return (
    <Overlay onClick={handleClose}>
      <Dialog onClick={(e) => e.stopPropagation()}>
        <Cross alt="" src="/close.png" onClick={handleClose} />
        {children}
      </Dialog>
    </Overlay>
  );
};

export default DynamicPopupDialog;
