import React from 'react';
import DynamicPopupDialog from './DynamicPopupDialog';
import Button from './Button';
import DropdownByEndpoint from './DropdownByEndpoint';
import styled from 'styled-components';
import { postData } from '../utils/axios';
import { useDynamicQueryMutation } from '../redux/api/authSlice';

const PopupWrapper = styled.div`
  display: grid;
  gap: 10px;
  button {
    justify-self: flex-end;
  }
`;

const TestDataTool = () => {
  const [show, setShow] = React.useState(false);
  const [object, setObject] = React.useState({});
  const [dynamicQuery, result] = useDynamicQueryMutation();

  const onRun = async () => {
    try {
      await dynamicQuery({
        endpoint: '/ai/saveTest',
        method: 'POST',
        body: {
          data: object,
        },
      });
    } catch (error) {}
  };

  return (
    <div>
      <Button theme="page" text="Run Test" onClick={() => setShow(true)} />
      <DynamicPopupDialog isOpen={show} onClose={setShow}>
        <PopupWrapper>
          <DropdownByEndpoint
            onChange={(e: any) =>
              setObject((prev) => ({ ...prev, functionId: e?.value }))
            }
            endpoint="/ai/testData"
            labelProperty="functionId"
            valueProperty="functionId"
            title="Filter Assistant"
          />
          <DropdownByEndpoint
            onChange={(e: any) =>
              setObject((prev) => ({ ...prev, applyAssistant: e?.value }))
            }
            endpoint="/ai/testData"
            labelProperty="functionId"
            valueProperty="functionId"
            title="Apply Assistant"
          />
          <Button theme="page" text="Run Test" onClick={onRun} />
        </PopupWrapper>
      </DynamicPopupDialog>
    </div>
  );
};

export default TestDataTool;
