/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useCallback, memo } from 'react';
import { config } from '../../config/config';
import { fetchData } from '../../utils/axios';
import { useAppSelector } from '../../redux/hooks';
import { selectGlobal } from '../../redux/state/globalSlice';
import styled from 'styled-components';

const StyledList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
`;

const StyledListItem = styled.li`
  padding: 10px 20px;
  border: 1px solid #e0e0e0;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 16px;
  &:hover {
    background-color: #f5f5f5;
  }
`;

type TagDetail = {
  detectedTag: string;
  type: 'html' | 'text' | 'unknown';
  storyExists: boolean;
};

const CustomContentTag = () => {
  const [stories, setStories] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [detectedTagsList, setDetectedTagsList] = useState<TagDetail[]>([]);
  const { currentHTML } = useAppSelector(selectGlobal);

  const getAllStories = async () => {
    setLoading(true);
    try {
      const stories = await fetchData(
        config.API_MAIN + '/public/getAllStories'
      );
      const returnedData = stories?.data?.map((st: any) => ({
        content_tag_name: st?.content_tag_name,
        mirrorText: st?.mirrorText,
        html: st?.story,
      }));
      setStories(returnedData || []);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const memoStory = useCallback(() => {
    getAllStories();
  }, []);

  useEffect(() => {
    memoStory();
  }, []);

  useEffect(() => {
    if (currentHTML.length && stories.length) {
      const tagDetails = detectTags(currentHTML);
      setDetectedTagsList(tagDetails);
    }
  }, [currentHTML, stories]);

  const detectTags = (htmlLines: string[]) => {
    const results: any[] = [];
    typeof htmlLines === 'object' &&
      htmlLines?.forEach((line) => {
        let matches = line.match(/\$(t|h)#(.*?)#/g) || [];
        matches.forEach((match: string) => {
          const type = match[1] === 'h' ? 'html' : 'text';
          const tagName = match.slice(3, -1);

          const storyMatch = stories.find(
            (story: any) => story.content_tag_name === tagName
          );

          const storyExists = !!storyMatch && !!storyMatch.mirrorText;

          results.push({
            detectedTag: tagName,
            type: type,
            storyExists: storyExists,
          });
        });
      });

    return results;
  };

  return (
    <div>
      {loading ? (
        'Loading...'
      ) : (
        <StyledList>
          {detectedTagsList.map((tag: any, index) => (
            <StyledListItem key={index}>
              <strong>Detected Tag: </strong>
              {tag.detectedTag} | <strong>Type:</strong>
              {tag.type} | <strong>Story Exists:</strong>{' '}
              {tag.storyExists ? 'Yes' : 'No'}
            </StyledListItem>
          ))}
        </StyledList>
      )}
    </div>
  );
};

export default memo(CustomContentTag);
