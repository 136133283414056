import React, { useEffect, useState } from 'react';
import DropdownByEndpoint from './DropdownByEndpoint';
import styled from 'styled-components';
import Button from './Button';
import addAssistantWithClass from '../static/staticPopups/addAssistantWithClass';
import addAssistantWithObjectName from '../static/staticPopups/addAssistantWithObjectName';
import addAssistantWithParse from '../static/staticPopups/addAssistantWithParse';
import addAssistantWithCustom from '../static/staticPopups/addAssistantWithCustom';
import addAssistantWithStatic from '../static/staticPopups/addAssistantWithStatic';
import addAssistantWithDynamic from '../static/staticPopups/addAssistantWithDynamic';
import { useAppDispatch } from '../redux/hooks';
import {
  setConditionalFormData,
  setCurrentAssistantState,
} from '../redux/state/globalSlice';
import useConditionalAssistantForm from '../hooks/useConditionalAssistantForm';

const Wrapper = styled.div`
  max-width: 500px;
  display: grid;
  grid-template-columns: 1fr 150px;
  align-items: center;
  align-content: center;
  gap: 20px;
`;

interface Iprops {
  setFechtedData: React.Dispatch<any>;
  fetchedData: any;
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setPickDefaultValueColumn: any;
}

const FromByAssistantType: React.FC<Iprops> = ({
  setFechtedData,
  fetchedData,
  setShowPopup,
  setPickDefaultValueColumn,
}) => {
  const [currentAssistant, setCurrentAssistant] = React.useState<any>({});
  const [cloneData, setCloneData] = useState([]);
  const [form, setForm] = useState();

  const dispatch = useAppDispatch();
  const { handleForm } = useConditionalAssistantForm();

  useEffect(() => {
    if (cloneData?.length === 0) {
      setCloneData(fetchedData);
    }
  }, [fetchedData]);

  const handleChange = (e: any) => {
    setCurrentAssistant(e?.value);
    const filterByType = cloneData.filter((data: any) => {
      if (
        data?.assistantTypeId &&
        data?.assistantTypeId === e?.value?.assistantTypeId
      ) {
        return data;
      }
    });
    setFechtedData(filterByType);
  };

  const onButtonClick = () => {
    setPickDefaultValueColumn([]);
    const value = currentAssistant?.function_type?.toLowerCase();
    dispatch(setCurrentAssistantState(currentAssistant));
    handleForm(value, '/ai/saveAssistant');
    setShowPopup(true);
  };

  return (
    <Wrapper>
      <DropdownByEndpoint
        zIndex={99}
        endpoint="/ai/assistant-type"
        labelProperty="assistant_type"
        onChange={handleChange}
      />
      <Button
        onClick={onButtonClick}
        text="create new"
        theme={Object.keys(currentAssistant)?.length ? 'page' : 'loading'}
      />
    </Wrapper>
  );
};

export default FromByAssistantType;
