/* eslint-disable no-new-func */
import React from 'react';
import Filter from '../components/Filter/Filter';
import styled from 'styled-components';
import Table from '../components/Table/Table';
import Stats from '../components/Stats/Stat';
import BarChart from '../components/BarChart/Index';
import { createFilterFunction } from '../utils/filterFunction';
import { useDynamicQueryMutation } from '../redux/api/authSlice';
import { useParams } from 'react-router';
import Button from '../components/Button';
import Popup from '../components/Popup';
import { FadeInDiv } from '../style/Animations';
import { JSONForm } from '../components/JSONForm';
import DeleteWarning from '../components/DeleteWarning';
import ImageGridGallery from '../components/ImageGridGallery';
import { exportData } from '../utils/exportJson';
import ImportCVS from '../components/importCVS';
import DotMenu from '../components/Layout/DotMenu';
import Rest from './Rest';
import ActionsMenu from '../components/Layout/ActionsMenu';
import PopupFunctions from '../components/PopupFunctions';
import DynamicPopupDialog from '../components/DynamicPopupDialog';
import JSONViewer from '../components/JSONViewer';
import FormConditionDropdown from '../components/FormConditionDropdown';
import FromByAssistantType from '../components/FromByAssistantType';
import { useAppSelector } from '../redux/hooks';
import { selectGlobal, setShowPopupGlobal } from '../redux/state/globalSlice';
import TestDataTool from '../components/TestDataTool';

const Wrapper = styled.div`
  display: grid;
  gap: 3rem;
  position: relative;
`;

const Breadcrumbs = styled.div`
  font-size: 1.5rem;
  color: #818181;
  text-transform: capitalize;
`;

const FilterWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
  justify-content: flex-start;
  gap: 2rem;
`;

const AddWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  justify-self: flex-start;
  width: 100%;
`;

const CVSWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-items: flex-start;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
`;

export interface IFilters {
  field: string;
  operator: string;
  value: string | number;
}

interface IDynamicPage {
  dataTableActive: boolean;
  functions: any;
  filtersActive: boolean;
  funnelActive: boolean;
  filtersList: any;
  dataTable: any;
  barChartActive: boolean;
  origin: string;
  endpoint: string;
  replaceByParam?: string[];
  barChartProperty: string;
  globalState: any;
  dispatch: any;
  popupData: any;
  updateDataEndpoint?: any;
  funnel?: any;
  addEnabled: boolean;
  breadcrumbs: any;
  imageGalleryActive: boolean;
  cvsEndpoint: string;
  timeBased: any;
  REST: boolean;
  actionsMenu: any;
  actionsMenuEnabled: boolean;
  formCondition: boolean;
  formConditionOptions: any;
  testDataApply: any;
  showPermissions: any;
}

const popupDataViewAccount = {
  title: 'Account Settings',
  endpoint: '/partner/update',
  list: [
    {
      name: 'partnerId',
      property: 'parentAccountUUID',
      type: 'input',
      label: 'Partner Id',
    },
    {
      name: 'partnerName',
      property: 'accountName',
      type: 'input',
      label: 'Partner Name',
    },
    // {
    //   name: 'Role',
    //   property: 'role',
    //   type: 'singleSelect',
    //   options: [
    //     { label: 'The Godfather', id: 1 },
    //     { label: 'Pulp Fiction', id: 2 },
    //   ],
    // },
  ],
};

const DynamicPage: React.FC<IDynamicPage> = ({
  dataTableActive,
  formCondition,
  functions,
  timeBased,
  filtersActive,
  imageGalleryActive,
  funnelActive,
  filtersList,
  dataTable,
  cvsEndpoint,
  barChartActive,
  popupData,
  origin,
  endpoint,
  replaceByParam,
  barChartProperty,
  globalState,
  dispatch,
  updateDataEndpoint,
  funnel,
  addEnabled,
  breadcrumbs,
  actionsMenuEnabled,
  actionsMenu,
  formConditionOptions,
  showPermissions,
  testDataApply,
  REST,
}) => {
  const [filters, setFilters] = React.useState<IFilters[]>([
    { operator: '===', value: 'all', field: 'all' },
  ]);
  const [fetchedData, setFechtedData] = React.useState<any>([]);
  const [fetchedOptions, setFetchedOptions] = React.useState<any>([]);

  const [dynamicQuery, result] = useDynamicQueryMutation();
  const [dataResult, setDataResult] = React.useState<any>([]);
  const [formData, setFormData] = React.useState<any>();
  const filterFunction = createFilterFunction(filters);
  const [pickDefaultValueColumn, setPickDefaultValueColumn] = React.useState(
    []
  );
  const [showPopup, setShowPopup] = React.useState(false);
  const [popupFunctionsData, setPopupFunctionsData] = React.useState(null);
  const [showButtonPopup, setShowButtonPopup] = React.useState(false);
  const params = useParams();
  const {
    settings,
    sideBarFormData,
    showDeleteAlert,
    showMenuToolForm,
    menuToolFormData,
    conditionalFormData,
    showAssistantEditForm,
    authenticated,
  } = globalState;
  const { showViewAccount } = settings;

  const userRole = authenticated?.user?.userRole;

  const handleParamReplace = () => {
    let endpointEnriched = endpoint;
    if (replaceByParam) {
      replaceByParam?.forEach((rp) => {
        endpointEnriched = endpointEnriched?.replaceAll(rp, params[rp] || '');
      });
    }
    return replaceByParam ? endpointEnriched : false;
  };

  React.useEffect(() => {
    dispatch(setShowPopupGlobal(showButtonPopup));
  }, [showButtonPopup]);

  React.useEffect(() => {
    setShowPopup(false);
    setPopupFunctionsData(null);
    setFechtedData([]);
    if (endpoint !== '/') {
      dynamicQuery({
        endpoint: handleParamReplace() || endpoint,
        method: 'POST',
        body: {},
      });
    }
  }, [dynamicQuery, endpoint, params?.value, params?.id]);

  React.useEffect(() => {
    if (result.status === 'fulfilled') {
      const dataWithId = result?.data?.data?.map((obj: any, index: number) => {
        return { id: index, ...obj };
      });
      setFechtedData(dataWithId);
      setFetchedOptions(result?.data?.activities);
    }
    if (result.status === 'rejected') {
      setFechtedData([]);
    }
  }, [result]);

  if (showPermissions && !showPermissions?.includes(userRole?.toLowerCase())) {
    return <h1>You don't have permission for this page!</h1>;
  }

  return (
    <Wrapper>
      <JSONViewer />
      <Breadcrumbs>{breadcrumbs}</Breadcrumbs>

      {formCondition && (
        <FromByAssistantType
          setFechtedData={setFechtedData}
          fetchedData={fetchedData}
          setShowPopup={setShowPopup}
          setPickDefaultValueColumn={setPickDefaultValueColumn}
        />
        // <FormConditionDropdown
        //   formConditionOptions={formConditionOptions}
        //   setConditionalFormData={setConditionalFormData}
        //   setPickDefaultValueColumn={setPickDefaultValueColumn}
        //   setShowPopup={setShowPopup}
        // />
      )}
      {testDataApply && <TestDataTool />}
      {showDeleteAlert && <DeleteWarning />}
      {popupFunctionsData && <PopupFunctions DATA={popupFunctionsData} />}
      {showMenuToolForm && (
        <Popup
          bodyProperty="updated"
          popupData={menuToolFormData}
          dispatch={dispatch}
          setFormData={setFormData}
          setShowPopup={setShowPopup}
          getDefaultValue={true}
          setShowButtonPopup={setShowButtonPopup}
          globalState={globalState}
          pushToArray={false}
          pickDefaultValueColumn={globalState?.rowObject}
          fetchedData={fetchedData}
          pickDefaultValueFrom={globalState?.rowObject}
        />
      )}
      {sideBarFormData && (
        <Popup
          bodyProperty="data"
          popupData={sideBarFormData}
          dispatch={dispatch}
          setFormData={setFormData}
          setShowPopup={setShowPopup}
          getDefaultValue={true}
          setShowButtonPopup={setShowButtonPopup}
          globalState={globalState}
          pushToArray={false}
          pickDefaultValueColumn={[]}
          fetchedData={fetchedData}
          pickDefaultValueFrom={
            globalState?.authenticated?.user?.selectedPartner
          }
          redirectAfterSave="/open-deals/management/all"
        />
      )}

      {showViewAccount && (
        <Popup
          bodyProperty="data"
          popupData={popupDataViewAccount}
          dispatch={dispatch}
          setFormData={setFormData}
          setShowPopup={setShowPopup}
          getDefaultValue={true}
          setShowButtonPopup={setShowButtonPopup}
          globalState={globalState}
          pushToArray={false}
          pickDefaultValueColumn={pickDefaultValueColumn}
          fetchedData={fetchedData}
          pickDefaultValueFrom={
            globalState?.authenticated?.user?.selectedPartner
          }
        />
      )}

      {/* Only on form conditions */}

      {showAssistantEditForm && formCondition && (
        <Popup
          bodyProperty="updated"
          globalState={globalState}
          popupData={conditionalFormData}
          setFormData={setFormData}
          dispatch={dispatch}
          setShowPopup={setShowPopup}
          pickDefaultValueColumn={pickDefaultValueColumn}
          getDefaultValue={false}
          pickDefaultValueFrom={[]}
          setFechtedData={setFechtedData}
          setShowButtonPopup={setShowButtonPopup}
          pushToArray={false}
          fetchedData={fetchedData}
          liveUpdateObject={true}
        />
      )}

      {showPopup && formCondition && (
        <Popup
          bodyProperty="data"
          globalState={globalState}
          popupData={conditionalFormData}
          setFormData={setFormData}
          dispatch={dispatch}
          setShowPopup={setShowPopup}
          pickDefaultValueColumn={pickDefaultValueColumn}
          getDefaultValue={false}
          pickDefaultValueFrom={[]}
          setFechtedData={setFechtedData}
          setShowButtonPopup={setShowButtonPopup}
          pushToArray={true}
          fetchedData={fetchedData}
        />
      )}

      {showPopup && !formCondition && (
        <Popup
          bodyProperty="data"
          globalState={globalState}
          popupData={popupData}
          setFormData={setFormData}
          dispatch={dispatch}
          setShowPopup={setShowPopup}
          pickDefaultValueColumn={pickDefaultValueColumn}
          getDefaultValue={false}
          pickDefaultValueFrom={[]}
          setFechtedData={setFechtedData}
          setShowButtonPopup={setShowButtonPopup}
          pushToArray={true}
          fetchedData={fetchedData}
        />
      )}
      {showButtonPopup && (
        <Popup
          bodyProperty="updated"
          globalState={globalState}
          popupData={formData}
          pickDefaultValueColumn={pickDefaultValueColumn}
          setFormData={setFormData}
          dispatch={dispatch}
          setShowPopup={setShowPopup}
          getDefaultValue={false}
          pickDefaultValueFrom={pickDefaultValueColumn}
          setFechtedData={setFechtedData}
          setShowButtonPopup={setShowButtonPopup}
          pushToArray={false}
          liveUpdateObject={true}
          fetchedData={fetchedData}
        />
      )}
      {funnelActive && (
        <Stats timeBased={timeBased} funnel={funnel} data={result?.data} />
      )}
      {filtersActive && (
        <FilterWrapper>
          {filtersList?.map((fl: any, index: number) => (
            <Filter
              origin={origin}
              key={index}
              propertyName={fl?.propertyName}
              title={fl?.title}
              operator={fl?.operator}
              data={fetchedData}
              setFilters={setFilters}
              filters={filters}
              single={fl?.single}
              customOptions={fl?.customOptions || fetchedOptions}
              findInObjectProperty={fl?.findInObjectProperty}
              setFechtedData={setFechtedData}
              dynamicQuery={dynamicQuery}
              handleParamReplace={handleParamReplace}
            />
          ))}
        </FilterWrapper>
      )}

      {
        <AddWrapper>
          {cvsEndpoint && (
            <DotMenu cvsEndpoint={cvsEndpoint} fetchedData={fetchedData} />
          )}
          <ActionsMenu
            setPopupFunctionsData={setPopupFunctionsData}
            actionMenu={actionsMenu}
          />
          {addEnabled && popupData && (
            <Button
              style={{ position: 'relative', bottom: 0 }}
              onClick={() => {
                setPickDefaultValueColumn([]);
                setShowPopup(!showPopup);
              }}
              theme="page"
              text={
                (params?.id && `Add ${params?.id?.replaceAll('_', ' ')}`) ||
                (popupData?.buttonTitle ? popupData?.buttonTitle : 'Add')
              }
            />
          )}
        </AddWrapper>
      }
      {REST && <Rest DATA={functions} />}
      {barChartActive && fetchedData?.length > 0 && (
        <BarChart
          barChartProperty={barChartProperty}
          obj={
            filters?.find((row: any) => row?.value === 'all')
              ? fetchedData
              : fetchedData?.filter(filterFunction)
          }
        />
      )}
      {imageGalleryActive && <ImageGridGallery data={result?.data} />}
      {dataTableActive && !result?.isLoading ? (
        <Table
          funnel={funnel}
          updateDataEndpoint={updateDataEndpoint}
          rows={
            filters?.find((row: any) => row?.value === 'all')
              ? fetchedData
              : fetchedData?.filter(filterFunction)
          }
          columns={dataTable?.columns}
          hideInitial={dataTable?.hideInitial}
          setShowButtonPopup={setShowButtonPopup}
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          showPop
          dataTable={dataTable}
          setFormData={setFormData}
          setPickDefaultValueColumn={setPickDefaultValueColumn}
        />
      ) : (
        dataTableActive && (
          <FadeInDiv>
            <p>DATA LOADING...</p>
          </FadeInDiv>
        )
      )}
    </Wrapper>
  );
};

export default DynamicPage;
