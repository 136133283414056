/* eslint-disable styled-components-a11y/no-onchange */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Dropdown from 'react-select';
import { ControlProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { useDynamicQueryMutation } from '../../redux/api/authSlice';
import { useAppSelector } from '../../redux/hooks';
import { selectGlobal } from '../../redux/state/globalSlice';
import { ReadOnly } from './ReadOnlyStyle';

const Label = styled.label`
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 1rem;
`;

const Wrapper = styled.div`
  margin: 1rem 0;
`;

const Select = styled.select`
  width: 100%;
  height: 40px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

interface DropdownControlProps extends ControlProps {
  data: string;
  handleChange: (path: string, value: any) => void;
  path: string;
  id: any;
}

const AssistantTypeFetch: React.FC<DropdownControlProps> = (props) => {
  const [options, setOptions] = useState<any>([]);

  const [dynamicQuery, result] = useDynamicQueryMutation();
  const global = useAppSelector(selectGlobal);
  const handleSelectChange = (event: any) => {
    props.handleChange(props.path, event.value);
  };
  // Assuming fetchOptions is a function that returns a Promise that resolves to the array of options
  const fetchOptions = async () => {
    try {
      dynamicQuery({
        endpoint: '/ai/assistant-type',
        method: 'POST',
        body: {
          foundObject: global?.rowObject,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  useEffect(() => {
    if (result.status === 'fulfilled') {
      setOptions(result?.data?.data);
    }
  }, [result]);

  useEffect(() => {
    if (global?.currentAssistant?.assistant_type) {
      const event = { value: global?.currentAssistant?.assistantTypeId };
      handleSelectChange(event);
    }
  }, [global?.currentAssistant?.assistant_type]);

  if (result?.isLoading) {
    return <p>Type Options are loading...</p>;
  }

  const dropdownOptions = options.map((option: any) => ({
    value: option?.assistantTypeId, // Assuming this is a string that uniquely identifies this option
    label: option?.assistant_type, // The label that will be displayed in the dropdown
  }));

  const currentSelectedOption = dropdownOptions.find(
    (option: any) => option.value === props.data
  );

  return (
    <Wrapper>
      <Label htmlFor={props.id}>Assistant Type</Label>
      <Dropdown
        id={props.id}
        // options={dropdownOptions}
        // onChange={(e) => handleSelectChange(e)}
        value={
          currentSelectedOption || {
            value: global?.currentAssistant?.assistantTypeId,
            label: global?.currentAssistant?.assistant_type,
          }
        }
        placeholder="Select..."
      />
      {/* <Select id={props.id} value={props.data} onChange={handleSelectChange}>
        <option>Select...</option>
        {options.map((option: any, index: any) => (
          <option key={index} value={option?.assistant_type}>
            {option?.assistant_type}
          </option>
        ))}
      </Select> */}
    </Wrapper>
  );
};

export default withJsonFormsControlProps(AssistantTypeFetch);
