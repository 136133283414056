import React from 'react';
import addAssistantWithClass from '../static/staticPopups/addAssistantWithClass';
import addAssistantWithObjectName from '../static/staticPopups/addAssistantWithObjectName';
import addAssistantWithParse from '../static/staticPopups/addAssistantWithParse';
import addAssistantWithCustom from '../static/staticPopups/addAssistantWithCustom';
import addAssistantWithStatic from '../static/staticPopups/addAssistantWithStatic';
import addAssistantWithDynamic from '../static/staticPopups/addAssistantWithDynamic';
import { useAppDispatch } from '../redux/hooks';
import { setConditionalFormData } from '../redux/state/globalSlice';

const useConditionalAssistantForm = () => {
  const dispatch = useAppDispatch();

  /**
   * This function is responsible for handling the form conditionally based on the given value.
   * @param {string} value - The value based on which the form behavior is conditioned.
   * @param {string} endpoint - to add /ai/saveAssistant' to update /ai/updateAssistant'

   */
  const handleForm = (value: string, endpoint: string) => {
    console.log(value, endpoint);
    if (value === 'classify' || value === 'insights' || value === 'decide') {
      dispatch(setConditionalFormData(addAssistantWithClass(endpoint)));
    } else if (value === 'extract' || value === 'generate') {
      dispatch(setConditionalFormData(addAssistantWithObjectName(endpoint)));
    } else if (value === 'parse') {
      dispatch(setConditionalFormData(addAssistantWithParse(endpoint)));
    } else if (value === 'custom') {
      dispatch(setConditionalFormData(addAssistantWithCustom(endpoint)));
    } else if (value === 'static') {
      dispatch(setConditionalFormData(addAssistantWithStatic(endpoint)));
    } else if (value === 'dynamic') {
      dispatch(setConditionalFormData(addAssistantWithDynamic(endpoint)));
    } else {
      dispatch(setConditionalFormData(addAssistantWithClass(endpoint)));
    }
  };

  return { handleForm };
};

export default useConditionalAssistantForm;
